import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { getCategoryColor } from "../utils"

const SC = {}

const PostCardVertical = ({ post, className, isCategoryPage = false }) => (
  <SC.PostCard
    className={className}
    title={post.title}
    to={`/${post.slug}/`}
    categoryColor={getCategoryColor(post.tags[0].name)}
  >
    <SC.ImageWrapper imgSrc={post.feature_image} className="image-wrapper" />
    <SC.Title>{post.title}</SC.Title>
    <SC.Description>{post.excerpt}</SC.Description>
    {!isCategoryPage ? (
      <SC.Category
        to={`/${post.tags[0].slug}/`}
        color={getCategoryColor(post.tags[0].name)}
      >
        {post.tags[0].name}
      </SC.Category>
    ) : null}
  </SC.PostCard>
)

SC.PostCard = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 370px;
  height: 480px;
  margin-bottom: 90px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
    height: auto;
    margin-bottom: 50px;
  }

  &:hover {
    > span {
      color: ${({ categoryColor }) => categoryColor};
    }

    .image-wrapper {
      &:after {
        position: absolute;
        content: "";
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: ${({ categoryColor }) => categoryColor};
        mix-blend-mode: multiply;
        border-radius: ${({ theme }) => theme.sizes.DEFAULT_BORDER_RADIUS};
      }
    }
  }
`

SC.ImageWrapper = styled.div`
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  background-image: url("${({ imgSrc }) => imgSrc}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: ${({ theme }) => theme.sizes.DEFAULT_BORDER_RADIUS};
  position: relative;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    height: 180px;
  }

  &:after {
    transition: all 0.25s;
    content: "";
  }
`

SC.Title = styled.span`
  font-size: 22px;
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  color: ${({ theme }) => theme.colors.GREY_1};
  font-weight: 600;
  margin-top: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.25s;
`

SC.Description = styled.p`
  font-size: 16px;
  line-height: 22px;
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.GREY_3};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

SC.Category = styled(Link)`
  color: ${({ color }) => color};
  font-weight: 600;
  font-size: 16px;
  margin-top: -15px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    margin-top: -10px;
  }

  &:hover {
    text-decoration: underline;
  }
`

export default PostCardVertical
