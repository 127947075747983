import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Container from "../components/container"

const SC = {}

const PostListHeader = ({ title, description, color }) => {
  return (
    <SC.Container>
      <SC.Heading color={color} dangerouslySetInnerHTML={{ __html: title }} />
      <SC.Description dangerouslySetInnerHTML={{ __html: description }} />
    </SC.Container>
  )
}

SC.Container = styled(Container)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    padding: 0 25px;
    margin-top: 80px;
  }
`

SC.Heading = styled.h1`
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  font-size: 32px;
  color: ${({ color }) => color};
  margin-top: 60px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 26px;
  }

  span {
    color: #000;
  }
`

SC.Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  font-size: 18px;
  color: ${({ theme }) => theme.colors.GREY_3};
  width: 50%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
  }

  a {
    color: ${({ theme }) => theme.colors.PRIMARY_PURPLE};
    text-decoration: underline;
    font-weight: 600;
  }
`

PostListHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
}

export default PostListHeader
