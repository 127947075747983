import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostListHeader from "../components/post-list-header"
import theme from "../consts/theme"
import PostCardVertical from "../components/post-card-vertical"
import Container from "../components/container"
import DefaultProfileImage from "../images/favicon.png"

const SC = {}

const Articles = ({ data, pageContext }) => (
  <Layout>
    <SEO
      title="Todos os Artigos"
      description="Desenvolva seu potencial cognitivo de maneira leve e descomplicada com
        nossas dicas sobre aprendizagem, estudos, soft skills e comportamento."
      canonical="artigos/"
      jsonld={{
        "@context": "https://schema.org",
        "@type": "Series",
        publisher: {
          "@type": "Organization",
          name: "Apetrecho Digital",
          url: data.site.siteMetadata.siteUrl,
          logo: `${data.site.siteMetadata.siteUrl}${DefaultProfileImage}`,
        },
        url: `${data.site.siteMetadata.siteUrl}artigos/`,
        name: "Todos os Artigos",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": data.site.siteMetadata.siteUrl,
        },
        description:
          "Desenvolva seu potencial cognitivo de maneira leve e descomplicada com nossas dicas sobre aprendizagem, estudos, soft skills e comportamento.",
      }}
    />
    <PostListHeader
      title="Todos os Artigos"
      description="Desenvolva seu potencial cognitivo de maneira leve e descomplicada com nossas dicas sobre aprendizagem, estudos, soft skills e comportamento."
      color={theme.colors.PRIMARY_PURPLE}
    />
    <SC.ArticlesList
      isFlex
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      {data.allGhostPost.edges.map(post => (
        <PostCardVertical key={post.node.id} post={post.node} />
      ))}
    </SC.ArticlesList>
    <SC.Pagination>
      {pageContext.previousPagePath ? (
        <SC.Link to={pageContext.previousPagePath}>
          <FontAwesomeIcon icon="arrow-left" color="#000" />
          <span>Anterior</span>
        </SC.Link>
      ) : null}
      {pageContext.nextPagePath ? (
        <SC.Link to={pageContext.nextPagePath}>
          <span>Próxima</span>
          <FontAwesomeIcon icon="arrow-right" color="#000" />
        </SC.Link>
      ) : null}
    </SC.Pagination>
  </Layout>
)

SC.ArticlesList = styled(Container)`
  margin-top: 80px;
  margin-bottom: 60px;
  flex-wrap: wrap;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    padding: 0 25px;
  }
`

SC.Pagination = styled.div`
  margin-top: 30px;
  margin-bottom: 60px;
  display: block;
  text-align: center;
`

SC.AdContainer = styled(Container)`
  margin-bottom: 60px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    padding: 0 25px;
    width: 100%;
  }
`

SC.Link = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  margin: 0 15px;

  span {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    margin: 0 15px;
    vertical-align: text-bottom;
  }
`

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allGhostPost(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: published_at }
    ) {
      edges {
        node {
          id
          excerpt
          title
          slug
          feature_image
          tags {
            name
            slug
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export default Articles
